.access-nft-loader-container div {
  width: 20%;
  margin: 1.5rem;
}
main {
  font-family: "Poppins", sans-serif;
  min-height: 760px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.access-nft-certificate-code-box {
  background-image: url(images/bg-img.webp);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: cover;
  max-width: 740px;
  border-radius: 9px;
  overflow: hidden;
  margin: 1.5rem;
}
.access-nft-main-container {
  padding: 1.5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 1fr);
  gap: 1.5rem;
}
.access-nft-img-container {
  grid-area: 1 / 1 / 3 / 2;
}
.access-nft-img-container img {
  width: 100%;
  object-fit: contain;
}
.access-nft-details-container {
  grid-area: 1 / 2 / 2 / 4;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
  padding-top: 2rem;
  padding-right: 1rem;
}
.access-nft-details-arrow {
  position: absolute;
  top: -0.4rem;
  left: -1.3rem;
  width: 14%;
}
.access-nft-details-arrow img {
  height: 100%;
  width: 100%;
}
.access-nft-details-container h2 {
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 700;
  line-height: 26.5px;
  letter-spacing: 0.44px;
  margin: 0;
}
.access-nft-details-container p {
  color: rgba(255, 255, 255, 0.67);
  font-size: 14px;
  line-height: 128%;
  margin: 0;
}
.access-nft-details-container strong {
  color: #ffffff;
}
.access-nft-content-container {
  grid-area: 2 / 2 / 2 / 4;
  padding-bottom: 2rem;
}
.access-nft-code-container {
  background: rgba(217, 217, 217, 0.13);
  border-radius: 4px;
  display: flex;
  width: max-content;
  overflow: hidden;
  margin-bottom: 0.6rem;
}
.access-nft-code {
  font-size: 24px;
  font-family: "Gemsbuck 01", sans-serif;
  font-weight: 700;
  line-height: 17.599px;
  letter-spacing: 6.84px;
  padding: 1rem;
}
.access-nft-copy-code {
  padding: 0.6rem;
  background: rgba(217, 217, 217, 0.13);
  color: rgba(255, 255, 255, 0.52);
  font-size: 10px;
  line-height: 120%;
  display: flex;
  align-items: center;
  gap: 0.6rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.access-nft-copy-code:hover {
  scale: 1.1;
  color: #ffffff;
}
.access-nft-sub-text {
  font-size: 11px;
  font-style: italic;
  font-weight: 300;
  line-height: 128%;
  letter-spacing: 0.385px;
  margin: 0;
}
.access-nft-steps-container {
  margin-top: 1.6rem;
  display: flex;
  gap: 2rem;
}
.access-nft-step {
  display: flex;
  gap: 0.6rem;
  flex: 1;
}
.access-nft-step-count {
  font-size: 12px;
  font-family: "Gemsbuck 01", sans-serif;
  font-weight: 700;
  min-width: 18px;
}
.access-nft-step-details {
  font-size: 12px;
  line-height: 120%;
}
.access-nft-bottom-container {
  background: rgba(255, 255, 255, 0.11);
  display: flex;
  overflow: hidden;
}
.access-nft-bottom-left-section {
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.6rem;
  font-size: 14px;
  line-height: 128%;
}
.access-nft-bottom-right-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  padding: 1rem 1.5rem;
  min-width: max-content;
  background: rgba(11, 10, 22, 0.51);
  cursor: pointer;
  color: #ffffff;
  text-decoration: none;
  font-size: 14px;
  line-height: 128%;
  transition: all 0.2s ease-in-out;
}
.access-nft-bottom-right-section:hover {
  scale: 1.1;
}
.access-nft-bottom-right-section:visited {
  color: #ffffff;
}
@media screen and (max-width: 768px) {
  .access-nft-img-container {
    grid-area: 1 / 1 / 5 / 2;
  }
  .access-nft-details-container {
    grid-area: 1 / 2 / 5 / 4;
  }
  .access-nft-content-container {
    grid-area: 5 / 1 / 6 / 4;
  }
}
@media screen and (max-width: 528px) {
  .access-nft-main-container {
    gap: 1rem;
    padding: 1rem;
  }
  .access-nft-details-container {
    gap: 1rem;
    padding-top: 1rem;
    padding-right: 0;
  }
  .access-nft-details-arrow {
    left: -0.8rem;
  }
  .access-nft-content-container {
    padding-bottom: 0;
  }
  .access-nft-details-container h2 {
    font-size: 18.378px;
    line-height: 20.676px;
    letter-spacing: 0.368px;
  }
  .access-nft-details-container p {
    font-size: 11.027px;
    line-height: 128%;
  }
  .access-nft-code {
    font-size: 22.054px;
    line-height: 16.172px;
    letter-spacing: 6.285px;
  }
  .access-nft-sub-text {
    font-size: 10.108px;
    letter-spacing: 0.354px;
  }
  .access-nft-steps-container {
    flex-wrap: wrap;
    row-gap: 1rem;
  }
  .access-nft-step {
    flex: auto;
    max-width: 40%;
  }
  .access-nft-step-count,
  .access-nft-step-details {
    font-size: 11.549px;
  }
  .access-nft-bottom-left-section {
    font-size: 10.86px;
  }
  .access-nft-bottom-right-section {
    gap: 0.2rem;
    flex-direction: column-reverse;
  }
}
@media screen and (max-width: 428px) {
  .access-nft-certificate-code-box {
    margin: 1rem;
  }
  .access-nft-details-container h2 {
    font-size: 16px;
    line-height: 120%;
  }
  .access-nft-bottom-left-section,
  .access-nft-bottom-right-section {
    padding: 1rem;
  }
  .access-nft-bottom-left-section img {
    width: 22px;
  }
  .access-nft-bottom-right-section img {
    width: 14px;
  }
}
@media screen and (max-width: 380px) {
  .access-nft-details-container h2 {
    font-size: 13.6px;
  }
  .access-nft-details-container p {
    font-size: 10.2px;
  }
  .access-nft-code {
    font-size: 18px;
  }
  .access-nft-copy-code {
    font-size: 9px;
  }
  .access-nft-step-count,
  .access-nft-step-details {
    font-size: 10px;
  }
  .access-nft-bottom-left-section {
    font-size: 8.5px;
  }
  .access-nft-bottom-right-section {
    font-size: 11px;
  }
}
